<template>
  <div class="setUpPage animate__animated animate__fadeIn">
    <header>
      <Headers :header_data="[{title: '个人设置'}]"/>
    </header>
    <div class="main">
      <div class="tabs">
        <el-tabs tab-position="left" v-model="tabs" @tab-click="tabClick(tabs)" :stretch="false" style="height: 200px;">
          <el-tab-pane name="information">
            <div slot="label" class="tablabel">
              <i>
                <img src="../../../assets/images/student/bluecs.png" v-if="tabs == 'information'" alt="">
                <img src="../../../assets/images/student/ccccs.png" v-else alt="">
              </i>
              <span>基本资料</span>
            </div>
          </el-tab-pane>
          <el-tab-pane label="常见问题" name="problem">
            <span slot="label" class="tablabel">
              <i>
                <img src="../../../assets/images/student/bluejb.png" v-if="tabs == 'problem'" alt="">
                <img src="../../../assets/images/student/cccjb.png" v-else alt="">
              </i>
              <span>常见问题</span>
            </span>
          </el-tab-pane>
          <el-tab-pane label="意见反馈" name="opinion">
            <span slot="label" class="tablabel">
              <i>
                <img src="../../../assets/images/student/blueph.png" v-if="tabs == 'opinion'" alt="">
                <img src="../../../assets/images/student/cccph.png" v-else alt="">
              </i>
              <span>意见反馈</span>
            </span>
          </el-tab-pane>
        </el-tabs>
      </div>
      <router-view></router-view>
    </div>
    <Footer />
  </div>
</template>
<script>
export default {
  components:{
    Headers:(resolve) => {
      require(['@/components/student/header.vue'], resolve)
    },
    Footer:(resolve) => {
      require(['@/components/student/footer.vue'], resolve)
    }
  },
  data(){
    return{
      tabs:'information'
    }
  },
  methods:{
    tabClick(val){
      if(val == 'information'){
        this.$router.replace({name:'information'})
      } else if(val == 'problem'){
        this.$router.replace({name:'commonproblem'})
      } else if(val == 'opinion'){
        this.$router.replace({name:'opinion'})
      }
    }
  },
  mounted(){
    this.tabs = this.$route.name
  }
}
</script>
<style lang="scss" scoped>
.setUpPage{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  header{
    width: 100%;
    height: 90px;
    box-shadow: 0 2px 4px 2px rgba(89,144,255,.1);
  }
  .main{
    position: absolute;
    top: 90px;
    left: 0;
    bottom: 50px;
    right: 0;
    box-sizing: border-box;
    padding: 2% 0;
    .tabs{
      height: 100%;
      width: 10vw;
      border-radius: 10px;
      box-shadow: 0 0 8px 4px rgba(89,144,255,.2);
      float: left;
      box-sizing: border-box;
      padding-top: 2%;
      .tablabel{
        position: relative;
        display: block;
        width:fit-content;
        width:-webkit-fit-content;
        width:-moz-fit-content;
        height: 100%;
        // margin: auto;
        i{
          width: 1.1vw;
          height: 1.1vw;
          display: block;
          float: left;
          margin-top: 3px;
          margin-right: .4vw;
          img{
            display: inline-block;
            width: 1.1vw;
            height: 1.1vw;
          }
        }
        span{
          display: block;
          float: left;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.setUpPage{
  .el-tabs__header.is-left {
    width: 10vw;
    .el-tabs__active-bar{
      display: none;
    }
    #tab-first {
      font-size: 20px;
      padding: 0;
      // text-align: center;
    }
    .is-active {
      background-color: #d8effb;
    }
    .el-tabs__header.is-left .el-tabs__nav > div {
      width: 3px !important;
    }
  }
  .el-tabs__nav{
    padding-top: 10px;
  }
  .el-tabs__item{
    color: #999;
    height: 3vw;
    line-height: 3vw;
    font-size: 1.1vw;
  }
  .el-tabs__item.is-active {
    color: #409EFF !important;
    border-right: 5px solid #409EFF;
  }
  .el-tabs__item:hover {
    color: #409EFF !important;
  }
  .el-tabs__header.is-left .is-active{
    background: #FFFFFF !important;
    box-shadow: 0px 0px 10px 0px rgba(89, 144, 255, 0.3) !important;
  }
}
</style>